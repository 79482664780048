<template>
<a href="./newsInfo" class="news">
    <div class="newscard">
        <div class="labelNewscard">
            <span>2022/9/22</span>
        </div>
        <div class="newsImg">
            
        </div>
        
        <p class="newsTitle">تعاون مشترك بين وارث الانبياء ومشفى الامام زين العابدين لتدريب طلبة كلية التمريض</p>
        <p>تخصصهم الوظيفي عبر توفير كافة المستلزمات الضرورية وعقد الاتفاقيات مع المشافي وبقية المراكز ... </p>
    </div>
</a>
</template>

<script>
export default {
    name: 'newsCard'
}

</script>

<style>
.news{
    text-decoration: none;
}
.newscard{
    margin: auto;
    width: 300px;
    height: 300px;
    box-shadow: 0px 1px 5px rgb(0, 0, 0, 30%);
    border-radius: 15px;
}
.newscard:hover{
    box-shadow: 0px 3px 7px rgb(0, 0, 0, 40%);
}
.labelNewscard{
    position: absolute;
    padding: 5px 10px;
    margin-top: 20px;
    border-radius: 0px 5px 5px 0px;
    color: #fff;
    background: #2D4E89;
}
.newsImg{
    background-image: url('../assets/homeimg.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 70%;
    border-radius: 20px 20px 0px 0px;
        box-shadow: inset 0 -90px 76px rgb(0 0 0 / 80%);

    
}
.newsTitle{
    text-align: right;
    padding: 5px 8px;
    font-size: 18px;
    color: #ffffff !important;
    font-weight: 500 !important;
    margin-bottom: 0px;
    margin-top: -85px !important;
}
.newscard p{
    font-size: 16px;
    color: black;
    text-align: right;
    direction: rtl;
    padding: 5px 8px;
    font-weight: 800;
    }
</style>