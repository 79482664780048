<template>
<div class="homeSection">
    <div class="homecontent">
        <h1>مركز بصمة للتطوير الاعلامي</h1>
        <p>
            هو منظمة غير حكومية اسس برؤيا مشتركة من قبل متطوعين يؤمنون بغــــد افضـل يحمــل الازدهـــار والرفـاهيــة للشعــــب العراقي بفكر عابــر للعـــرق والجنـــس والانتمـــاء السياسي او الديني ويرسخ الهوية الوطنية
        </p>
    </div>
    <div class="homimg">
        <img alt="name" src="../assets/logo.png" class="logo">
    </div>
</div>
    
</template>

<script>
export default {
    name: 'homeImg'
}

</script>

<style>
.homeSection{
    height: 550px;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.homecontent{
    margin-top: 150px;
    height: 300px;
    justify-content: center; color: black;
}
.homecontent h1{
    font-size: 50px;
    padding-bottom: 10px;
}
.homecontent p{
    direction: rtl;
    font-size: 20px;
    margin: 5%;
    text-align: right;
}
.homimg{
    width: 50%;
}
.homimg img{
    width: 400px;
}
</style>