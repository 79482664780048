<template>
<div class="section">
    <div class="container">
        <h2 id="servsTilte">خدمات المركز</h2>
        <div class="d-flex justify-content-center align-items-center" id="servsFlex">
            <div class="servsCard">
                <div class="icon">
                        <div class="svg-icon">
                            <svg version="1.1" width="55px" fill="#fff" height="55px" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve">
                            <path id="XMLID_89_" d="M329.998,14.969c-0.001-0.479-0.025-0.957-0.072-1.434c-0.022-0.229-0.065-0.451-0.098-0.676
                            c-0.037-0.259-0.067-0.519-0.118-0.775c-0.05-0.255-0.12-0.502-0.184-0.752c-0.057-0.225-0.105-0.449-0.173-0.672
                            c-0.074-0.244-0.165-0.481-0.251-0.719c-0.08-0.223-0.153-0.447-0.244-0.667c-0.092-0.224-0.201-0.438-0.304-0.655
                            c-0.106-0.225-0.206-0.453-0.325-0.675c-0.112-0.21-0.24-0.409-0.362-0.613c-0.129-0.216-0.251-0.435-0.392-0.646
                            c-0.152-0.227-0.32-0.439-0.484-0.658c-0.13-0.173-0.251-0.351-0.389-0.519c-0.628-0.766-1.329-1.467-2.094-2.094
                            c-0.176-0.145-0.361-0.27-0.542-0.406c-0.21-0.157-0.417-0.32-0.635-0.467c-0.219-0.146-0.446-0.273-0.671-0.407
                            c-0.195-0.116-0.386-0.239-0.586-0.347c-0.231-0.124-0.468-0.229-0.704-0.34c-0.208-0.098-0.412-0.201-0.625-0.289
                            c-0.231-0.096-0.468-0.174-0.703-0.258c-0.227-0.081-0.45-0.168-0.682-0.238c-0.237-0.072-0.478-0.125-0.718-0.185
                            c-0.235-0.059-0.467-0.125-0.706-0.173c-0.279-0.055-0.561-0.089-0.842-0.128c-0.204-0.028-0.403-0.067-0.609-0.088
                            c-0.495-0.049-0.992-0.075-1.489-0.075H55c-13.909,0-27.15,4.186-37.285,11.787C6.457,20.244,0,32.351,0,45.014v239.973
                            c0,12.663,6.457,24.77,17.715,33.213C27.85,325.8,41.091,329.986,55,329.986c13.908,0,27.149-4.185,37.284-11.787
                            c6.667-5,11.633-11.289,14.589-18.213H315c8.284,0,15-6.716,15-15V15.014C330,14.998,329.998,14.984,329.998,14.969z M110,141.215
                            L138.799,170L110,198.799V141.215z M160.012,148.787L110,98.797V45.014c0-5.164-1.091-10.231-3.127-15h171.914L160.012,148.787z
                            M300,51.227v137.555l-68.778-68.777L300,51.227z M55,30.014c14.309,0,25,7.919,25,15c0,7.081-10.691,15-25,15
                            c-14.309,0-25-7.919-25-15C30,37.933,40.691,30.014,55,30.014z M55,299.986c-14.309,0-25-7.919-25-15V85.168
                            c7.653,3.162,16.191,4.846,25,4.846c8.809,0,17.346-1.684,25-4.846v199.818C80,292.067,69.309,299.986,55,299.986z M110,269.986
                            v-28.762l60.614-60.613c0.003-0.004,0.006-0.006,0.009-0.009c0.003-0.003,0.005-0.007,0.008-0.009l39.378-39.377L300,231.207v38.779
                            H110z"/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                        </svg>
                        <!-- <h3>الجدول الالكتروني</h3> -->
                        <p>
    المساهمة في عقد جلسات انضاج مفهوم عمل الحكومة اللامركزية ونقل الصلاحيات من الحكومة المركزية الى المحافظات بالتعوان مع المنظمات الاممية خاص وتنفيذ مشاريع للرصد والمراقبة للحدمات المقدمة من قبل الحكومات المحلية بغية تقديم النصح والارشاد لتطيور اليات العمل التي تصب بخدمة المجتمع                        </p>
                        <!-- <a href="/arabic/clc/" class="btn btn-warning bg2 fnormal">اضغط هنا</a> -->
                        </div>
                    </div>
            </div>
            <div class="servsCard">
                <div class="icon">
                        <div class="svg-icon">
                            <svg version="1.1" width="55px" fill="#fff" height="55px" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve">
                            <path id="XMLID_89_" d="M329.998,14.969c-0.001-0.479-0.025-0.957-0.072-1.434c-0.022-0.229-0.065-0.451-0.098-0.676
                            c-0.037-0.259-0.067-0.519-0.118-0.775c-0.05-0.255-0.12-0.502-0.184-0.752c-0.057-0.225-0.105-0.449-0.173-0.672
                            c-0.074-0.244-0.165-0.481-0.251-0.719c-0.08-0.223-0.153-0.447-0.244-0.667c-0.092-0.224-0.201-0.438-0.304-0.655
                            c-0.106-0.225-0.206-0.453-0.325-0.675c-0.112-0.21-0.24-0.409-0.362-0.613c-0.129-0.216-0.251-0.435-0.392-0.646
                            c-0.152-0.227-0.32-0.439-0.484-0.658c-0.13-0.173-0.251-0.351-0.389-0.519c-0.628-0.766-1.329-1.467-2.094-2.094
                            c-0.176-0.145-0.361-0.27-0.542-0.406c-0.21-0.157-0.417-0.32-0.635-0.467c-0.219-0.146-0.446-0.273-0.671-0.407
                            c-0.195-0.116-0.386-0.239-0.586-0.347c-0.231-0.124-0.468-0.229-0.704-0.34c-0.208-0.098-0.412-0.201-0.625-0.289
                            c-0.231-0.096-0.468-0.174-0.703-0.258c-0.227-0.081-0.45-0.168-0.682-0.238c-0.237-0.072-0.478-0.125-0.718-0.185
                            c-0.235-0.059-0.467-0.125-0.706-0.173c-0.279-0.055-0.561-0.089-0.842-0.128c-0.204-0.028-0.403-0.067-0.609-0.088
                            c-0.495-0.049-0.992-0.075-1.489-0.075H55c-13.909,0-27.15,4.186-37.285,11.787C6.457,20.244,0,32.351,0,45.014v239.973
                            c0,12.663,6.457,24.77,17.715,33.213C27.85,325.8,41.091,329.986,55,329.986c13.908,0,27.149-4.185,37.284-11.787
                            c6.667-5,11.633-11.289,14.589-18.213H315c8.284,0,15-6.716,15-15V15.014C330,14.998,329.998,14.984,329.998,14.969z M110,141.215
                            L138.799,170L110,198.799V141.215z M160.012,148.787L110,98.797V45.014c0-5.164-1.091-10.231-3.127-15h171.914L160.012,148.787z
                            M300,51.227v137.555l-68.778-68.777L300,51.227z M55,30.014c14.309,0,25,7.919,25,15c0,7.081-10.691,15-25,15
                            c-14.309,0-25-7.919-25-15C30,37.933,40.691,30.014,55,30.014z M55,299.986c-14.309,0-25-7.919-25-15V85.168
                            c7.653,3.162,16.191,4.846,25,4.846c8.809,0,17.346-1.684,25-4.846v199.818C80,292.067,69.309,299.986,55,299.986z M110,269.986
                            v-28.762l60.614-60.613c0.003-0.004,0.006-0.006,0.009-0.009c0.003-0.003,0.005-0.007,0.008-0.009l39.378-39.377L300,231.207v38.779
                            H110z"/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                        </svg>
                        <!-- <h3> الخطة السنوية</h3> -->
                        <p>
    المشاركة في برامج تعزيز حرية التعبية ومناصرة قوانين السلم الاجتماعي وحل النزاعات مع الحكومة المحلية والمنظمات الدولية والمشاركة في دعم الشرطة المجتمعية من خلال انشطة وبرامج هادفة للمجتمع بشكل عام وطلاب الاعداديات بشكل خاص                        </p>
                        <!-- <a href="/arabic/clc/" class="btn btn-warning bg2 fnormal">اضغط هنا</a> -->
                        </div>
                    </div>
            </div>
            <div class="servsCard">
                <div class="icon">
                        <div class="svg-icon">
                            <svg version="1.1" width="55px" fill="#fff" height="55px" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve">
                            <path id="XMLID_89_" d="M329.998,14.969c-0.001-0.479-0.025-0.957-0.072-1.434c-0.022-0.229-0.065-0.451-0.098-0.676
                            c-0.037-0.259-0.067-0.519-0.118-0.775c-0.05-0.255-0.12-0.502-0.184-0.752c-0.057-0.225-0.105-0.449-0.173-0.672
                            c-0.074-0.244-0.165-0.481-0.251-0.719c-0.08-0.223-0.153-0.447-0.244-0.667c-0.092-0.224-0.201-0.438-0.304-0.655
                            c-0.106-0.225-0.206-0.453-0.325-0.675c-0.112-0.21-0.24-0.409-0.362-0.613c-0.129-0.216-0.251-0.435-0.392-0.646
                            c-0.152-0.227-0.32-0.439-0.484-0.658c-0.13-0.173-0.251-0.351-0.389-0.519c-0.628-0.766-1.329-1.467-2.094-2.094
                            c-0.176-0.145-0.361-0.27-0.542-0.406c-0.21-0.157-0.417-0.32-0.635-0.467c-0.219-0.146-0.446-0.273-0.671-0.407
                            c-0.195-0.116-0.386-0.239-0.586-0.347c-0.231-0.124-0.468-0.229-0.704-0.34c-0.208-0.098-0.412-0.201-0.625-0.289
                            c-0.231-0.096-0.468-0.174-0.703-0.258c-0.227-0.081-0.45-0.168-0.682-0.238c-0.237-0.072-0.478-0.125-0.718-0.185
                            c-0.235-0.059-0.467-0.125-0.706-0.173c-0.279-0.055-0.561-0.089-0.842-0.128c-0.204-0.028-0.403-0.067-0.609-0.088
                            c-0.495-0.049-0.992-0.075-1.489-0.075H55c-13.909,0-27.15,4.186-37.285,11.787C6.457,20.244,0,32.351,0,45.014v239.973
                            c0,12.663,6.457,24.77,17.715,33.213C27.85,325.8,41.091,329.986,55,329.986c13.908,0,27.149-4.185,37.284-11.787
                            c6.667-5,11.633-11.289,14.589-18.213H315c8.284,0,15-6.716,15-15V15.014C330,14.998,329.998,14.984,329.998,14.969z M110,141.215
                            L138.799,170L110,198.799V141.215z M160.012,148.787L110,98.797V45.014c0-5.164-1.091-10.231-3.127-15h171.914L160.012,148.787z
                            M300,51.227v137.555l-68.778-68.777L300,51.227z M55,30.014c14.309,0,25,7.919,25,15c0,7.081-10.691,15-25,15
                            c-14.309,0-25-7.919-25-15C30,37.933,40.691,30.014,55,30.014z M55,299.986c-14.309,0-25-7.919-25-15V85.168
                            c7.653,3.162,16.191,4.846,25,4.846c8.809,0,17.346-1.684,25-4.846v199.818C80,292.067,69.309,299.986,55,299.986z M110,269.986
                            v-28.762l60.614-60.613c0.003-0.004,0.006-0.006,0.009-0.009c0.003-0.003,0.005-0.007,0.008-0.009l39.378-39.377L300,231.207v38.779
                            H110z"/><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
                        </svg>
                        <!-- <h3>دورات المركز</h3> -->
                        <p>
    المشاركة في كتابة عدد من مسودات القوانين مثل قانون التسامج الوطني والاستراتيجية الوطنية لعمل وزارة الشباب والرياضة والمشاركة في برامج الدعم النفسي للاطفال المشاركين في العمليات العسكرية مع منظمة اليونسيف                         </p>
                        <!-- <a href="/arabic/clc/" class="btn btn-warning bg2 fnormal">اضغط هنا</a> -->
                        </div>
                    </div>
            </div>
        </div>

    </div>

</div>

</template>

<script>
export default {
    name: 'servesBage'
}

</script>

<style>
.section{
    margin-top: 15px;
    width: 100%;
    height: 500px;
    background-color: #22356d;
}
#servsTilte{
    color: white;
    padding-top: 15px;
}
.servsCard{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 25%;
    height: 400px;
    background: linear-gradient(10deg, rgba(33, 52, 105, 1) 0%, rgba(45, 78, 173, 1) 35%, rgba(33, 52, 105, 1) 100%);
    border-radius: 20px 20px 150px 150px;
    margin: 0px 30px;
}
.servsCard h3{
    width: 200px;
    margin-top: 60px;
    color: white;
}
.servsCard p{
    direction: rtl;
    width: 300px;
    margin-top: 60px;
    color: white;
    text-align: justify;
  text-justify: inter-word;
    
}
.servsCard .btn{
    background-color: #ddca92;
    font-size: 18px;
}
.servsCard .btn:hover{
    background-color: #ffd043;
}
.icon{
    position: relative;
    width: 140px;
    height: 120px;
    display: flex;
    justify-content: center;
    background: #22356d;
    border-radius: 0px 0px 200px 200px;
}
.svg-icon{
    margin-top: 30px;
}
.servsCard:hover{
    box-shadow: 0px 7px 10px rgb(0, 0, 0, 30%);
}
</style>