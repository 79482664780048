<template>
  <nav class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <div class="navbar-brand" href="/"></div>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="/">الرئيسية</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="/news">النشاطات والمركز الاخباري</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link" href="/about" >
            حول المركز
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">اتصل بنا</a>
        </li>
      </ul>
    </div>
    <div>
      <div  class="d-flex justify-content-start align-items-center py-2 mh-100">
        <img id="logo" alt="name" src="../assets/logo.png" class="logo">
      </div>
    </div>
  </div>
</nav>
</template>

<script>
export default {
  name: 'NavBar',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar{
  direction: rtl;
  padding: 0px 10px;
  box-shadow: inset 0 30px 30px rgba(0, 0, 0, 0.3);
  background-color: #2b4288;
  height: 58px;
}
.navbar-nav{
  gap: 12px;
  font-size: 18px;
  font-weight: 500;
  padding-right: 25px;
}
#navbarNavDropdown{
  margin-top: 7px;
}
.navbar-nav a:hover{
  background-color: white;
  color: white;
  border-radius: 5px;
  color: #2b4288;
  font-weight: 500;
}
.navbar-brand{
  font-size: 32px;
  color: white;
  margin-top: -5px;
}
.navbar-nav li a{
  text-align: right;
  color: white;
}
.dropdown:hover .dropdown-menu {
  display: block;
  background-color: #2b4288;
  color: white;
}
#logo{
width: 50px;
}
</style>
