import { createRouter, createWebHistory } from 'vue-router'
import Home from './view/Home.vue'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import( './view/about.vue')
  },
  {
    path: '/vision',
    name: 'Vision',
    component: () => import( './view/vision.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import( './view/newsPage.vue')
  },
  {
    path: '/corses',
    name: 'corses',
    component: () => import( './view/corses.vue')
  },
  {
    path: '/secondHome',
    name: 'secondHome',
    component: () => import( './view/secondHome.vue')
  },
  {
    path: '/corsTable',
    name: 'corsTable',
    component: () => import( './view/corsTable.vue')
  },
  {
    path: '/newsInfo',
    name: 'newsInfo',
    component: () => import( './view/news.vue')
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router