<template>
<div class="numbercards">
    <div class="container">
        <div class="d-flex justify-content-center align-items-center">
            <div class="numbercard">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-archive-fill" viewBox="0 0 16 16">
                <path d="M12.643 15C13.979 15 15 13.845 15 12.5V5H1v7.5C1 13.845 2.021 15 3.357 15h9.286zM5.5 7h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1zM.8 1a.8.8 0 0 0-.8.8V3a.8.8 0 0 0 .8.8h14.4A.8.8 0 0 0 16 3V1.8a.8.8 0 0 0-.8-.8H.8z"/>
                </svg>          
                <p>عدد البرامج المنفذة</p>
                <p id="num">7</p>
            </div>
            <div class="numbercard">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-people-fill" viewBox="0 0 16 16">
                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                <path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                </svg>         
                <p>عدد المستفيدين</p>
                <p id="num">1400</p>
            </div>
            <div class="numbercard">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-clipboard-check-fill" viewBox="0 0 16 16">
                <path d="M6.5 0A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3Zm3 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3Z"/>
                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1A2.5 2.5 0 0 1 9.5 5h-3A2.5 2.5 0 0 1 4 2.5v-1Zm6.854 7.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708Z"/>
                </svg>          
                <p>عدد الورش</p>
                <p id="num">36</p>
            </div>


        </div>
    </div>
</div>
    
</template>

<script>
export default {
    name: 'homeNumber'
}

</script>

<style>
.numbercards{
    margin-top: 15px;
}
.numbercards .container .d-flex{
    gap: 10px;
}
.numbercard{ 
    width: 200px;
    height: 150px;
    margin: 20px;
    background-color: hsl(208, 100%, 97%, 70%);
    box-shadow: 0px 1px 5px rgb(0, 0, 0, 20%);
}
.numbercard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    padding-top: 10px;
    border-radius: 15px;
}
.numbercard p{
    font-size: 24px; 
    color: #2b4288;
    margin-bottom: 0px !important;
}
.numbercard svg{
    color: #4b6cb7;  /* fallback for old browsers */
color: -webkit-linear-gradient(to right, #182848, #4b6cb7);  /* Chrome 10-25, Safari 5.1-6 */
color: linear-gradient(to right, #182848, #4b6cb7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
#num{
    font-size: 45px;
    font-weight: 600;
    margin-top: -10px !important;
}
</style>