<template>
<div class="container">
    <div class="card-flex">
        <div class="card">
            <div class="cardin">

            </div>
            <div class="cardlists">
                <div class="d-flex flex-column justify-content-center">
                    <a href="#"  class="cardlist">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
width="45" height="45"
viewBox="0 0 128 128"
style=" fill:#000000;"><path fill="#fff" d="M64 14A50 50 0 1 0 64 114A50 50 0 1 0 64 14Z"></path><path fill="#444b54" d="M64,117c-29.2,0-53-23.8-53-53s23.8-53,53-53s53,23.8,53,53S93.2,117,64,117z M64,17c-25.9,0-47,21.1-47,47s21.1,47,47,47s47-21.1,47-47S89.9,17,64,17z"></path><path fill="#444b54" d="M86.5 52h-45c-1.7 0-3-1.3-3-3s1.3-3 3-3h45c1.7 0 3 1.3 3 3S88.2 52 86.5 52zM86.5 67h-45c-1.7 0-3-1.3-3-3s1.3-3 3-3h45c1.7 0 3 1.3 3 3S88.2 67 86.5 67z"></path><g><path fill="#444b54" d="M86.5,82h-45c-1.7,0-3-1.3-3-3s1.3-3,3-3h45c1.7,0,3,1.3,3,3S88.2,82,86.5,82z"></path></g></svg>
<p>الدورات الحالية</p>
                    </a>
                                        <a href="#"  class="cardlist">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
width="45" height="45"
viewBox="0 0 128 128"
style=" fill:#000000;"><path fill="#fff" d="M64 14A50 50 0 1 0 64 114A50 50 0 1 0 64 14Z"></path><path fill="#659ec9" d="M104,34c-7.2-9.6-17.8-16.5-30-19v64l15-10l15,10V34z"></path><path fill="#fff" d="M94 4L94 64 79 54 64 64 64 -6"></path><path fill="#444b54" d="M64,117c-29.2,0-53-23.8-53-53s23.8-53,53-53c1.7,0,3,1.3,3,3s-1.3,3-3,3c-25.9,0-47,21.1-47,47s21.1,47,47,47s47-21.1,47-47c0-14.9-6.9-28.6-18.8-37.6c-1.3-1-1.6-2.9-0.6-4.2c1-1.3,2.9-1.6,4.2-0.6C109.3,31.7,117,47.2,117,64C117,93.2,93.2,117,64,117z"></path><path fill="#444b54" d="M64 67c-.5 0-1-.1-1.4-.4-1-.5-1.6-1.5-1.6-2.6V14c0-1.7 1.3-3 3-3s3 1.3 3 3v44.4l10.3-6.9c1-.7 2.3-.7 3.3 0l4.8 3.2c1.4.9 1.8 2.8.8 4.2-.9 1.4-2.8 1.8-4.2.8L79 57.6l-13.3 8.9C65.2 66.8 64.6 67 64 67zM94 67c-.2 0-.4 0-.6-.1-.2 0-.4-.1-.6-.2-.2-.1-.3-.2-.5-.3-.2-.1-.3-.2-.5-.4-.1-.1-.3-.3-.4-.5-.1-.2-.2-.3-.3-.5-.1-.2-.1-.4-.2-.6 0-.2-.1-.4-.1-.6 0-.2 0-.4.1-.6 0-.2.1-.4.2-.6.1-.2.2-.3.3-.5.1-.2.2-.3.4-.5.7-.7 1.7-1 2.7-.8.2 0 .4.1.6.2.2.1.4.2.5.3.2.1.3.2.5.4.1.1.3.3.4.5.1.2.2.3.3.5.1.2.1.4.2.6 0 .2.1.4.1.6 0 .2 0 .4-.1.6 0 .2-.1.4-.2.6-.1.2-.2.4-.3.5-.1.2-.2.3-.4.5C95.6 66.7 94.8 67 94 67zM94 56c-1.7 0-3-1.3-3-3V24c0-1.7 1.3-3 3-3s3 1.3 3 3v29C97 54.7 95.7 56 94 56z"></path></svg>
<p>الدورات السابقة</p>
                    </a>
                                        <a href="#"  class="cardlist">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
width="45" height="45"
viewBox="0 0 128 128"
style=" fill:#000000;"><path fill="#fff" d="M108.9,108.9L108.9,108.9c-2.3,2.3-6.1,2.3-8.5,0L87.7,96.2c-2.3-2.3-2.3-6.1,0-8.5l0,0c2.3-2.3,6.1-2.3,8.5,0l12.7,12.7C111.2,102.8,111.2,106.6,108.9,108.9z"></path><path fill="#fff" d="M52.3 17.299999999999997A35 35 0 1 0 52.3 87.3A35 35 0 1 0 52.3 17.299999999999997Z" transform="rotate(-45.001 52.337 52.338)"></path><path fill="#fff" d="M52.3 17.299999999999997A35 35 0 1 0 52.3 87.3A35 35 0 1 0 52.3 17.299999999999997Z" transform="rotate(-45.001 52.337 52.338)"></path><path fill="#71c2ff" d="M52.3 84.3c-1.7 0-3-1.3-3-3s1.3-3 3-3c6.9 0 13.5-2.7 18.4-7.6 6.4-6.4 9-15.5 6.9-24.4-.4-1.6.6-3.2 2.2-3.6 1.6-.4 3.2.6 3.6 2.2C86 55.8 82.9 67.1 75 75 68.9 81 60.9 84.3 52.3 84.3zM73 35c-.8 0-1.5-.3-2.1-.9L70.7 34c-1.2-1.2-1.2-3.1 0-4.2 1.2-1.2 3.1-1.2 4.2 0l.2.2c1.2 1.2 1.2 3.1 0 4.2C74.5 34.7 73.8 35 73 35z"></path><path fill="#444b54" d="M52.3 90.3c-9.7 0-19.5-3.7-26.9-11.1-14.8-14.8-14.8-38.9 0-53.7 14.8-14.8 38.9-14.8 53.7 0h0C94 40.3 94 64.4 79.2 79.2 71.8 86.6 62.1 90.3 52.3 90.3zM52.3 20.4c-8.2 0-16.4 3.1-22.6 9.4-12.5 12.5-12.5 32.8 0 45.3C42.2 87.4 62.5 87.4 75 75c12.5-12.5 12.5-32.8 0-45.3C68.7 23.5 60.5 20.4 52.3 20.4zM111 98.3L98.3 85.6c-1.7-1.7-4-2.6-6.4-2.6-1.4 0-2.7.3-3.9.9l-2.5-2.5c-1.2-1.2-3.1-1.2-4.2 0-1.2 1.2-1.2 3.1 0 4.2l2.5 2.5c-.6 1.2-.9 2.5-.9 3.9 0 2.4.9 4.7 2.6 6.4L98.3 111c1.8 1.8 4.1 2.6 6.4 2.6s4.6-.9 6.4-2.6l0 0C114.5 107.5 114.5 101.8 111 98.3zM106.8 106.8c-1.2 1.2-3.1 1.2-4.2 0L89.8 94.1c-.6-.6-.9-1.3-.9-2.1s.3-1.6.9-2.1c.6-.6 1.3-.9 2.1-.9s1.6.3 2.1.9l12.7 12.7C108 103.7 108 105.6 106.8 106.8z"></path></svg>
 <p>استمارة تسجيل الدورات</p>
                    </a>
                                        <a href="#"  class="cardlist">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
width="45" height="45"
viewBox="0 0 128 128"
style=" fill:#000000;"><path fill="#FFF" d="M65.1,15.9C38.5,15.9,17,37.4,17,64s21.5,48.1,48.1,48.1s48.1-21.5,48.1-48.1S91.6,15.9,65.1,15.9z"></path><path fill="#454B54" d="M65.1,115.1C36.9,115.1,14,92.2,14,64s22.9-51.1,51.1-51.1s51.1,22.9,51.1,51.1S93.2,115.1,65.1,115.1z M65.1,18.9C40.2,18.9,20,39.1,20,64s20.2,45.1,45.1,45.1s45.1-20.2,45.1-45.1S89.9,18.9,65.1,18.9z"></path><path fill="#E97583" d="M83.9,82.2c-0.7,0-1.4-0.2-2-0.7l-18.8-15c-0.8-0.6-1.2-1.5-1.2-2.5V29.8c0-1.8,1.4-3.2,3.2-3.2c1.8,0,3.2,1.4,3.2,3.2v32.6l17.6,14.1c1.4,1.1,1.6,3.1,0.5,4.5C85.7,81.8,84.8,82.2,83.9,82.2z"></path><path fill="#454B54" d="M65.1 55.4A7.5 7.5 0 1 0 65.1 70.4A7.5 7.5 0 1 0 65.1 55.4Z"></path></svg>
<p>البحث عن شهادة</p>
                    </a>
                </div>
            </div>
            
        </div>
    </div>
    
</div>
</template>

<script>
export default {
    name: 'homeCard'
}

</script>

<style>
.card-flex{
    direction: rtl;
    display: flex;
    justify-content: center;
    align-items: center !important;
}
.card{
    flex-direction: row;
    width: 60%;
    height: 270px;
    border: none;
    background-color: hsl(208, 100%, 97%, 70%);
    border-radius: 12px;
    margin: 5px;
    box-shadow: 0px 1px 5px rgb(0, 0, 0, 20%);
}
.cardin{
    width: 45%;
    height: 245px;
    border: none;
    background-color: #22356d;
    border-radius: 12px;
    margin: 15px;
}
.cardlists{
width: 55%;
}
.cardlists .d-flex{
    margin-top: 10px;
}
.cardlist{
    display: grid;
    grid-template-columns: 13% 80%;
    height: 57.5px;
    border: none;
    background: #4b6cb7;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #182848, #4b6cb7);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #182848, #4b6cb7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 12px;
    margin: 5px;
    margin-right: 0px !important;
    margin-left: 15px;
    color: white;
    text-decoration: none;
    font-size: 18px;
    padding: 6px 4px;
    margin-bottom: 0px;
}
.cardlist p{
    text-align: right;
    padding: 8px 10px;
}
.cardlist:hover{
    color: white;
        background: #4b6cb7;  /* fallback for old browsers */
background: -webkit-linear-gradient(to left, #182848, #4b6cb7);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to left, #182848, #4b6cb7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border: 1px solid #22356d;
    font-weight: 500;
}
.cardlist svg{
    background-color: #1828487d;
    border-radius: 10px;
}
</style>