<template>
    <div class="footer">
         <div id="container">
        <div id="contacts">
          <div id="contacts--contact">
            <p>info@basmaiq.org</p>
            <p>iraq - karbala</p>
          </div>
          <div id="contacts--social">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div id="mentions">
          <p>مركز بصمة للتطوير الاعلامي</p>
        </div>
       </div>
    </div>
</template>

<script>
export default {
    name: 'servesBage'
}

</script>

<style>
.footer{
  display:flex;
  background-color:#F0F8FF;
  height:40vh;
  justify-content:center;
  align-items:flex-end;
  font-family:Open Sans;
}
#container{
  padding-top:40px;
  margin-top: -20px;
  width:100%;
  height: 200px;
  color:white;
  background-color: #22356d;
  display:flex;
  flex-direction:column;
  align-items:center;
}
#contacts{
  display:flex;
  justify-content:space-around;
  align-items:center;
  width:100%;
}
#contacts--contact{
  text-align:left;
  font-size: 1.5em;
}
#contacts--social{
  width:20%;
  display : flex;
  justify-content : space-between;
}
#contacts--social div{
  height:30px;
  width:30px;
  background-color:white;
  border-radius:50%;
}
#mentions p {
    margin-top: 15px;
  font-size:1em;
  font-weight: 600;
}
</style>