<template>
  <Home/>
  
  <hCard/>
  <Number/>
  <Serves/>
  <br/>
  <NewsSwiper/>
  <br/>
  <br/>
  <br/>
  <br/>
</template>

<script>
import Home from '../components/home.vue';
import hCard from '../components/hcard.vue';
import Serves from '../components/serves.vue';
import Number from '../components/number.vue';
import NewsSwiper from '../components/newsSwiper.vue'




export default {
  name: 'App',
  components: {
    Home,
    hCard,
    Serves,
    Number,
    NewsSwiper
}
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
